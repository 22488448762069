import { httpClient } from "../../HttpClientV1/HttpClient";
import router from "../../router/index";
import axios from "axios";
export default {
  state: {
    plans: [],
    accounts: [],
    accordion: [],
    partners: [
      {
        fio: null,
        individual: true,
        share: null,
      },
      {
        fio: null,
        individual: true,
        share: null,
      },
    ],
    stripe_id: null,
    plan_id: null,
    price: null,
    paid_plan_id: null,
    names: null,
    description: null,
    uploaded_document: [],
    codes: null,
    other_second_name: null,
    persons_control: null,
    responsible_partners: null,
    status: null,
    partners2: [],
    forms: [],
    file: [],
    locale: localStorage.getItem("locale"),
    partner_id: null,
    document_id: null,
    code: null,
    statusDoc: null,
    colM: null,
    form_id: null,
  },
  mutations: {
    updateAccordion(state, data) {
      if (state.accounts[data].started == 0) {
        state.accounts[data].started = 1;
      } else {
        state.accounts[data].started = 0;
      }
    },
    updateColM(state, data) {
      state.colM = data;
    },
    updateStatusDoc(state, data) {
      state.statusDoc = data;
    },
    updateFormId(state, data) {
      state.form_id = data;
    },
    updateStripeID(state, data) {
      state.stripe_id = data;
    },
    updatePartnerId(state, data) {
      state.partner_id = data;
    },
    updateCode(state, data) {
      state.code = data;
    },
    updateDocumentId(state, data) {
      state.document_id = data;
    },
    updateUploadedFile(state, data) {
      state.uploaded_document.push(data);
    },
    updatePrice(state, data) {
      state.price = data;
    },
    updateAccordion_data(state, data) {
      state.accordion = data;
    },
    updateForms(state, data) {
      state.forms = data;
    },
    updatepResponsiblePartners(state, data) {
      state.responsible_partners = data;
    },
    updatepStatus(state, data) {
      state.status = data;
    },
    updatepPartners2(state, data) {
      state.partners2 = data;
    },
    updatePlanId(state, data) {
      state.plan_id = data;
    },
    updatePaidPlanId(state, data) {
      state.paid_plan_id = data;
    },
    updateNames(state, data) {
      state.names = data;
    },
    updateDescription(state, data) {
      state.description = data;
    },
    updateCodes(state, data) {
      state.codes = data;
    },
    updatePersonsControl(state, data) {
      state.persons_control = data;
    },
    updateOtherSecondName(state, data) {
      state.other_second_name = data;
    },
    updatePlans(state, data) {
      state.plans = data;
    },
    updateAccounts(state, data) {
      state.accounts = data;
    },
    updatePartners(state, data) {
      state.partners = data;
    },
    resetUploadedFile(state) {
      state.uploaded_document = [];
    },
  },
  actions: {
    fetchDocuments(ctx) {
      var documents = {
        partner_id: ctx.state.partners[id],
        document_id: ctx.state.partners[documents[id]],
        file: ctx.state.file,
        code: ctx.state.code,
      };
      httpClient
        .post("easypay/api/v4/send_form", documents)
        .then((response) => {
          ctx.commit("updateStatusDoc", response.data.status);
          for (var i = 0; i < response.data.parnters.length; i++) {
            for (
              var j = 0;
              j < response.data.parnters[i].documents.length;
              j++
            ) {
              if (response.data.parnters[i].documents[j].count > 1) {
                for (
                  var k = 0;
                  k < response.data.parnters[i].documents[j].active.length;
                  k++
                ) {
                  if (
                    response.data.parnters[i].documents[j].active[k] == true
                  ) {
                    ctx.commit("updateUploadedFile", documents);
                  }
                }
              } else {
                if (response.data.parnters[i].documents[j].active == true) {
                  ctx.commit("updateUploadedFile", documents);
                }
              }
            }
          }
        });
    },
    fetchDocuments2(ctx, form_id) {
      httpClient
        .post("easypay/api/v4/form_verification", { form_id: form_id })
        .then((response) => {
          ctx.commit("updateStatusDoc", response.data);
          ctx.dispatch("fetchForms");
          ctx.dispatch("resetUploadedFile");
        });
    },
    setCode(ctx, data) {
      ctx.commit("updateCode", data);
    },
    setStripeID(ctx, data) {
      ctx.commit("updateStripeID", data);
    },
    setPartnerId(ctx, data) {
      ctx.commit("updatePartnerId", data);
    },
    setDocumentId(ctx, data) {
      ctx.commit("updateDocumentId", data);
    },
    setFormId(ctx, data) {
      ctx.commit("updateFormId", data);
    },
    buyProxy(ctx) {
      return new Promise((resolve, reject) => {
        var data = {
          id: ctx.state.stripe_id,
        };
        httpClient
          .post("easypay/bay_proxy", { data })
          .then((resp) => {
            ctx.commit("updateStripeID", null);
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //обработчик отправки файлов на сервер
    uploadFile(ctx, data) {
      let formData = new FormData();
      formData.append("file", data.file);
      formData.append("code", data.code);
      formData.append("partner_id", data.partner_id);
      formData.append("document_id", data.document_id);
      httpClient
        .post("easypay/api/v4/file/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          var data_new_document = {
            file: resp.data[0].file,
            partner_id: data.partner_id,
            document_id: data.document_id,
            code: data.code,
          };
          ctx.commit("updateUploadedFile", data_new_document);
        });
    },
    setPrice(ctx, data) {
      ctx.commit("updatePrice", data);
    },
    setAccordion(ctx, data) {
      ctx.commit("updateAccordion", data);
    },
    setResponsiblePartners(ctx, data) {
      ctx.commit("updatepResponsiblePartners", data);
    },
    setPlanId(ctx, data) {
      ctx.commit("updatePlanId", data);
    },
    setPaidPlanId(ctx, data) {
      ctx.commit("updatePaidPlanId", data);
    },
    setNames(ctx, data) {
      ctx.commit("updateNames", data);
    },
    setDescription(ctx, data) {
      ctx.commit("updateDescription", data);
    },
    setCodes(ctx, data) {
      ctx.commit("updateCodes", data);
    },
    setPersonsControl(ctx, data) {
      ctx.commit("updatePersonsControl", data);
    },
    setOtherSecondName(ctx, data) {
      ctx.commit("updateOtherSecondName", data);
    },
    addNewElementPartners(ctx) {
      var null_data = {
        fio: null,
        individual: true,
        share: null,
      };
      var partners = ctx.state.partners;
      if (partners.length < 5) {
        partners.push(null_data);
      }
      ctx.commit("updatePartners", partners);
    },
    udalElementPartners(ctx, data) {
      var partners = ctx.state.partners;
      if (partners.length > 1) {
        partners.splice(data, 1);
      }
      ctx.commit("updatePartners", partners);
    },
    setPartners(ctx, data) {
      ctx.commit("updatePartners", data);
    },
    fetchPlans(ctx) {
      httpClient
        .get(
          "easypay/api/v4/plans?locale=" +
            localStorage.getItem("locale").toLowerCase() +
            "&promocode=" +
            localStorage.getItem("promocode")
        )
        .then((response) => {
          ctx.commit("updatePlans", response.data);
        });
    },
    sendDocuments(ctx) {
      httpClient
        .post("easypay/api/v4/send_documents", {
          paid_plan_id: ctx.state.paid_plan_id,
        })
        .then((response) => {
          console.log(response.data);
          ctx.commit("resetUploadedFile");
          ctx.dispatch('fetchForms')
        });
    },
    fetchAccounts(ctx) {
      httpClient.get("easypay/api/v4/accounts").then((response) => {
        var accordion = [];
        ctx.commit("updateAccounts", response.data);
        for (var i = 0; i < response.data.length; i++) {
          accordion.push({
            id: "accordion-" + i.toString(),
            state: false,
          });
        }
        ctx.commit("updateAccordion_data", accordion);
      });
    },
    fetchBuyPlan(ctx) {
      return new Promise((resolve, reject) => {
        var data = {
          plan_id: ctx.state.plan_id,
        };
        httpClient
          .post(
            "easypay/api/v4/buy_plan?promocode=" +
              localStorage.getItem("promocode"),
            data
          )
          .then((response) => {
            ctx.commit("updatepStatus", response.data);
            ctx.commit("updatePaidPlanId", response.data.paid_plan_id);
            ctx.dispatch("fetchTemplateBalance");
            ctx.dispatch("fetchForms");
            localStorage.removeItem("promocode");
            // router.push("/company");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchSendForm(ctx, paid_plan_id) {
      var data = {
        partners: ctx.state.partners,
        paid_plan_id: paid_plan_id,
        names: ctx.state.names,
        description: ctx.state.description,
        codes: ctx.state.codes,
        other_second_name: ctx.state.other_second_name,
        persons_control: ctx.state.persons_control,
        responsible_partners: ctx.state.persons_control,
      };
      httpClient.post("easypay/api/v4/send_form", data).then((response) => {
        ctx.commit("updatepStatus", response.data);
        ctx.commit("updatepPartners2", response.data.partners);
        ctx.commit("updatePaidPlanId", paid_plan_id);
        // ctx.commit("updatePartners", []);
        ctx.commit(
          "resetUploadedFile",
        );
        var col = 0;
        for (var i = 0; i < response.data.partners.length; i++) {
          for (var j = 0; j < response.data.partners[i].documents.length; j++) {
            col += response.data.partners[i].documents[j].count;
          }
        }
        ctx.commit("updateColM", col);
        for (var i = 0; i < response.data.partners.length; i++) {
          for (var j = 0; j < response.data.partners[i].documents.length; j++) {
            if (response.data.partners[i].documents[j].count > 1) {
              for (
                var k = 0;
                k < response.data.partners[i].documents[j].active.length;
                k++
              ) {
                if (response.data.partners[i].documents[j].active[k] == true) {
                 
                  ctx.commit(
                    "updateUploadedFile",
                    response.data.partners[i].documents[j]
                  );
                }
              }
            } else {
              if (response.data.partners[i].documents[j].active == true) {
                ctx.commit(
                  "updateUploadedFile",
                  response.data.partners[i].documents[j]
                );
              }
            }
          }
        }
      });
    },
    fetchForms(ctx, data) {
      httpClient.post("easypay/api/v4/forms", data).then((response) => {
        // localStorage.setItem('names', response.data[0].data_form[0].names);
        // localStorage.setItem('description', response.data[0].data_form[0].description);
        // localStorage.setItem('codes', response.data[0].data_form[0].codes);
        // localStorage.setItem('other_second_name', response.data[0].data_form[0].other_second_name);
        // localStorage.setItem('persons_control', response.data[0].data_form[0].persons_control);
        // localStorage.setItem('responsible_partners', response.data[0].data_form[0].responsible_partners);

        ctx.commit("updateForms", response.data);
        if (response.data.length > 1) {
          for (var i = 0; i < response.data.length; i++) {
            console.log(response.data[i].status);
            if (response.data[i].status == 'created') {
              ctx.commit("updateNames", response.data[i].data_form[i].names);
              ctx.commit(
                "updateDescription",
                response.data[i].data_form[i].description
              );
              ctx.commit("updateCodes", response.data[i].data_form[i].codes);
              ctx.commit(
                "updatePersonsControl",
                response.data[i].data_form[i].persons_control
              );
              ctx.commit(
                "updateOtherSecondName",
                response.data[i].data_form[i].other_second_name
              );
              ctx.commit(
                "updatepResponsiblePartners",
                response.data[i].data_form[i].responsible_partners
              );
              if (response.data[i].partner_data.length == 0) {
                var partners_data = [
                  {
                    fio: null,
                    individual: true,
                    share: null,
                  },
                  {
                    fio: null,
                    individual: true,
                    share: null,
                  },
                ];
                ctx.commit("updatePartners", partners_data);
              } else {
                ctx.commit("updatePartners", response.data[i].partner_data);
              }
            }
          }
        } else {
          ctx.commit("updateForms", response.data);
          ctx.commit("updateNames", response.data[0].data_form[0].names);
          ctx.commit(
            "updateDescription",
            response.data[0].data_form[0].description
          );
          ctx.commit("updateCodes", response.data[0].data_form[0].codes);
          ctx.commit(
            "updatePersonsControl",
            response.data[0].data_form[0].persons_control
          );
          ctx.commit(
            "updateOtherSecondName",
            response.data[0].data_form[0].other_second_name
          );
          ctx.commit(
            "updatepResponsiblePartners",
            response.data[0].data_form[0].responsible_partners
          );
          if (response.data[0].partner_data.length == 0) {
            var partners_data = [
              {
                fio: null,
                individual: true,
                share: null,
              },
              {
                fio: null,
                individual: true,
                share: null,
              },
            ];
            ctx.commit("updatePartners", partners_data);
          } else {
            ctx.commit("updatePartners", response.data[0].partner_data);
          }
        }
      });
    },
  },
  getters: {
    getUploaded_document: (state) => state.uploaded_document,
    getColM: (state) => state.colM,
    getStatusDoc: (state) => state.statusDoc,
    getPrice: (state) => state.price,
    getAccordion: (state) => (index) => {
      return state.accordion.find((accordion) => accordion.id === index);
    },
    getForms: (state) => state.forms,
    getPartners2: (state) => state.partners2,
    getResponsiblePartners: (state) => state.responsible_partners,
    getPlanId: (state) => state.plan_id,
    getPaidPlanId: (state) => state.paid_plan_id,
    getNames: (state) => state.names,
    getDescription: (state) => state.description,
    getCodes: (state) => state.codes,
    getPersonsControl: (state) => state.persons_control,
    getOtherSecondName: (state) => state.other_second_name,
    getPartners: (state) => state.partners,
    getPlans: (state) => state.plans,
    getAccounts: (state) => state.accounts,
  },
};
