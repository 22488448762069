<template>
  <div>
    <b-modal id="modal7" class="model1" ref="my-modal7" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH37M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Создание почты на домене</h1>
      <p class="P1Text mt-4">Множество почтовых сервисов предлагают создать аккаунт и работать с письмами абсолютно бесплатно, чем и пользуются люди по всему миру. Но если вы решили завести аккаунт для бизнеса, лучше использовать корпоративный адрес.</p>
      <p class="P1Text mt-4">Корпоративная почта – это email-адрес бренда, который расположен на вашем домене. Выглядит такой адрес так: subjectname@companyname.com, где subjectname – это имя менеджера, сотрудника или даже целого отдела (support, press, sales), а companyname.com – домен вашего сайта.</p>
      <p class="P1Text mt-4">Создание корпоративной почты даст владельцам организаций такие преимущества:</p>
      <ul class="P1Text mt-4">
        <li>узнаваемое имя бренда: когда клиент получает от вас рассылку, ему должно быть достаточно одного взгляда, чтобы понять, от кого и отчасти почему пришло это письмо;</li>
        <li class="mt-2">работа – отдельно: с корпоративным почтовым аккаунтом нет необходимости смешивать личную и рабочую почту в одном ящике. Две сферы жизни разделены, да и переключиться на рабочий лад проще, когда рекламные рассылки и личные мейлы не отвлекают;</li>
        <li class="mt-2">более высокий процент доставляемости емейл-рассылки: правильно настроенная корпоративная почта использует технологии DKIM и SPF, что позволяет повысить процент доставленных писем по сравнению с письмами, отправляемыми с общих доменов.</li>
        <li class="mt-2">другие возможности: с корпоративной почтой легко использовать разные инструменты и почтовые сервисы, отчего появляются новые возможности для компании.</li>
      </ul>
      <p class="P1Text mt-4">Если вы владелец интернет-магазина, вам в любом случае необходимо зарегистрировать почту на домене. Мы предлагаем воспользоваться сервисом biz.mail.ru, с помощью которого можно абсолютно бесплатно создать корпоративную почту в течение 10 минут.</p>
<a href="https://docs.google.com/document/d/1ChoGg6INXLtkrZmuHCyGaaRY67NGpbKmNrRN-W4vfYY/edit" target="_blank" class="mt-4 mb-4 widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal7'].hide()
    },
  }
}
</script>