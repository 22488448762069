<template>
  <div>
    <b-modal id="modal1" class="model1" ref="my-modal1" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH31M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Чек-лист по поиску товаров</h1>
      <p class="P1Text mt-4"> Если вы занимаетесь дропшиппингом с помощью Shopify и сталкиваетесь с проблемой поиска актуальных товаров, которые будут продаваться и приносить вам прибыль, мы предлагаем вам оптимальное решение.</p>
      <p class="P1Text mt-4">Команда Ecom Today на основе своего опыта подготовила для вас топовый чек-лист по поиску и отбору товаров (winning products), с помощью которого вы сможете быстро определять потенциальные выигрышные товары, а также отбрасывать уже "заезженные" и не актуальные. Изучив данный документ, вы сэкономите большое количество денежных средств на тест товаров и времени на поиск необходимой информации касательно product research.</p>
      <p class="P1Text mt-4">В чек-лист входит следующая информация:</p>
      <ul class="P1Text mt-4">
        <li>Критерии выигрышного товара;</li>
        <li class="mt-2">Формула Winning Product;</li>
        <li class="mt-2">Примеры товаров;</li>
        <li class="mt-2">Виды товаров;</li>
        <li class="mt-2">Жизненный цикл товара;</li>
        <li class="mt-2">Категории товаров, которые не рекомендуется продавать;</li>
        <li class="mt-2">Чек-лист по отбору товаров;</li>
        <li class="mt-2">Советы по выбору поставщика;</li>
        <li class="mt-2">Поиск товаров с помощью Facebook / Instagram;</li>
        <li class="mt-2">Поиск товаров с помощью Google;</li>
        <li class="mt-2">Поиск товаров в Aliexpress;</li>
        <li class="mt-2">Поиск через сторонние сервисы;</li>
        <li class="mt-2">Список spy-сервисов для поиска товаров и креативов;</li>
        <li class="mt-2">Пошаговый алгоритм поиска товаров;</li>
      </ul>
       <a href="https://drive.google.com/file/d/1aG4t6jFk0Wopfeh9-ZkMrsRzerxXv1yK/view?usp=sharing" target="_blank" class="mt-4 mb-4 widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal1'].hide()
    },
  }
}
</script>