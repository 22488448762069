<template>
  <div>
    <b-modal id="modal2" class="model1" ref="my-modal2" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH32M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Чек-лист по поддержке клиентов</h1>
      <p class="P1Text mt-4"> Обеспечение поддержки клиентов – трудоемкая и затратная по времени ежедневная работа. Мы разработали подробный гайд по работе с покупателями, который значительно сэкономит ваши временные и материальные ресурсы на обучение персонала и вполне может заменить найм такого специалиста.</p>
      <p class="P1Text mt-4">Мы составили оптимальные скрипты сообщений и сформулировали основные принципы профессиональной клиентской поддержки в дропшиппинг-бизнесе; разобрали самые распространенные проблемы, с которыми обращаются покупатели интернет-магазинов и составили грамотные ответы на них.</p>
      <p class="P1Text mt-4">Вы узнаете, как:</p>
      <ul class="P1Text mt-4">
        <li>работать с задержанными заказами;</li>
        <li class="mt-2">что делать, если товара нет в наличии у поставщика;</li>
        <li class="mt-2">как правильно отвечать на вопросы о сроках доставки, складах, производстве и т. д.;</li>
        <li class="mt-2">как и какие документы необходимо запрашивать при подозрении на мошенничество;</li>
        <li class="mt-2">что делать, если многие покупатели требуют возврат?;</li>
        <li class="mt-2">какие каналы связи лучше использовать для общения с покупателями;</li>
        <li class="mt-2">как значительно снизить расходы на рефаунды;</li>
        <li class="mt-2">различные способы упростить работу с заказами и лайфхаки, которые значительно сократят время на обработку вопросов;</li>
        <li class="mt-2">список самых распространенных вопросов (универсальных для любой ниши) для FAQ page и лучшие ответы на них.</li>
      </ul>
      <p class="P1Text mt-4">А также, вы получите простые примеры того, как с помощью ведения диалога на почте или в социальных сетях сохранить недовольных покупателей и стимулировать их к повторной покупке.</p>
      <p class="P1Text mt-4">Объем документа – 45 страниц ценной информации.</p>
     <a href="https://drive.google.com/file/d/1J8fUFZgTdTHL6goR8AKSi2k2llh01UCt/view?usp=sharing" target="_blank" class="mt-4 mb-4 widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal2'].hide()
    },
  }
}
</script>