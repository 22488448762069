<template>
  <div>
    <b-modal id="modalP" class="model1" ref="modalP" centered hide-footer hide-header size="md">
      <div @click="hideModal" style="width:10px;height:10px;position:absolute;top:3px;right:10px;cursor:pointer">
        <img src="../assets/img/close.svg" alt="">
      </div>
      <h1 class="H1Heading mt-3">Новый пароль отправлен</h1>
      <p class="P1Text mt-3 mb-4">Мы отправили новый пароль на указнный e-mail.</p>
      <router-link to="/login" class=" w-100"><b-button class="btnPR w-100">Авторизация</b-button></router-link>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['modalP'].hide()
    },
  }
}
</script>