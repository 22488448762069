<template>
<div>
   <div class="NavBar d-none d-lg-flex">
       <img src="../assets/img/logoE.svg" alt="">
       <div class="menuNav ">
           <div class="strmenu1" :class="[routname == 2 ? 'strmenu1B' : '']"></div>
           <router-link to="/" @click="form = false" exact  style="text-decoration: none;">
           <div class="strmenu H2Heading"  :class="[routname == 1 ? 'strmenuB' : '']">
               Главная
           </div>
           </router-link>
           <router-link to="/balance" @click="form = false" style="text-decoration: none;">
           <div class="strmenu H2Heading"   style="right:130px" :class="[routname == 2 ? 'strmenuB' : '']">
               Пополнение баланса
           </div>
           </router-link>
           <div class="strmenu H2Heading" @click="logout()" style="right:0px;cursor:pointer">
             <span >Выйти</span> 
           </div>
       </div>
       <div class="d-flex flex-column align-items-end" style="position:relative">
           <span class="H2Heading">Баланс: {{balance}} €</span>
           <span class="H2Heading widthmail"  @click ="st = true" style="cursor:pointer">{{email}}</span>
           <div v-if="st == true" class="emailpop">
           <div @click="close" class="close"><img src="../assets/img/close.svg" alt=""></div>
           <p class="P1Text mt-2">E-mail: {{email}}</p>
           <div class="d-flex justify-content-between">
           <p class="P1Text">Пароль: ************</p>
           <p class="P1Text" style="text-decoration:underline;cursor:pointer" @click ="form = true">изменить</p>
           </div>
           <b-form v-if="form == true" @submit.prevent="ChangePasswordMethod">
               <validation-provider name="password"
                                            :rules="{ required: true, min: 8 }" v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input class="input " style="margin-top:25px" placeholder="Введите старый пароль"
                                                    type="password" v-model="old_password"
                                                    :state="getValidationState(validationContext)" autocomplete="off">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider name="password"
                                            :rules="{ required: true, min: 8 }" v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input class="input " style="margin-top:25px" placeholder="Введите новый пароль"
                                                    type="password" v-model="new_password"
                                                    :state="getValidationState(validationContext)" autocomplete="off">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider name="password"
                                            :rules="{ required: true, min: 8 }" v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input class="input " style="margin-top:25px" placeholder="Введите новый пароль ещё раз"
                                                    type="password" v-model="new_password_replay"
                                                    :state="getValidationState(validationContext)" autocomplete="off">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <div class="d-flex justify-content-center mt-3" v-if="error_change_pwd!=null">
                                        {{error_change_pwd}}
                                        </div>
                                        <b-button class="btnPR w-100 mt-4 mb-2" @click="sub" type="submit" style="padding:0 30px">
                                            Изменить пароль
                                        </b-button>
           </b-form>
       </div>
       </div>
   </div>
   <div class="d-flex justify-content-between mt-4 d-lg-none" style="position:relative;padding-top: 35px;">
       <img src="../assets/img/logoE.svg" alt="">
       <div class="d-flex flex-column align-items-end">
           <span class="H2Heading">Баланс: {{balance}} €</span>
           <span class="H2Heading widthmail"  @click ="st = true" style="cursor:pointer">{{email}}</span>
       </div>
       <div v-if="st == true" class="emailpop">
           <div @click="close" class="close"><img src="../assets/img/close.svg" alt=""></div>
           <p class="P1Text mt-2">E-mail: {{email}}</p>
           <div class="d-flex justify-content-between">
           <p class="P1Text">Пароль: ************</p>
           <p class="P1Text" style="text-decoration:underline;cursor:pointer" @click ="form = true">изменить</p>
           </div>
           <b-form v-if="form == true" @submit.prevent="ChangePasswordMethod">
               <validation-provider name="password"
                                            :rules="{ required: true, min: 8 }" v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input class="input " style="margin-top:25px" placeholder="Введите старый пароль"
                                                    type="password" v-model="old_password"
                                                    :state="getValidationState(validationContext)" autocomplete="off">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider name="password"
                                            :rules="{ required: true, min: 8 }" v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input class="input " style="margin-top:25px" placeholder="Введите новый пароль"
                                                    type="password" v-model="new_password"
                                                    :state="getValidationState(validationContext)" autocomplete="off">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <validation-provider name="password"
                                            :rules="{ required: true, min: 8 }" v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input class="input " style="margin-top:25px" placeholder="Введите новый пароль ещё раз"
                                                    type="password" v-model="new_password_replay"
                                                    :state="getValidationState(validationContext)" autocomplete="off">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <div class="d-flex justify-content-center mt-3" v-if="error_change_pwd!=null">
                                        {{error_change_pwd}}
                                        </div>
                                        <b-button class="btnPR w-100 mt-4 mb-2" @click="sub" type="submit" style="padding:0 30px">
                                            Изменить пароль
                                        </b-button>
           </b-form>
       </div>
   </div>
   <div class="d-flex d-lg-none mt-3 justify-content-center">
       <div class="menuNav">
           <div class="strmenu1" :class="[routname == 2 ? 'strmenu1B' : '']"></div>
           <router-link to="/" exact @click="form = false" style="text-decoration: none;">
           <div class="strmenu H2Heading"  :class="[routname == 1 ? 'strmenuB' : '']" style="font-size:16px;padding:7px 15px;height:42px">
               Главная
           </div>
           </router-link>
           <router-link to="/balance" @click="form = false" style="text-decoration: none;">
           <div class="strmenu H2Heading"  style="right:70px;font-size:16px;padding:7px 10px;height:42px" :class="[routname == 2 ? 'strmenuB' : '']">
               Пополнение баланса
           </div>
           </router-link>
           <div class="strmenu H2Heading" @click="logout()" style="right:10px;padding:6px 10px;height:42px;cursor:pointer">
             <img  src="../assets/img/logout.svg" alt=""> 
           </div>
       </div>
   </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            st:false,
            form:false,
            old_password: null,
            new_password: null,
            new_password_replay: null,
            error_change_pwd: null
        }
    },
     mounted() {
        this.fetchTemplateInfo()
    },
    computed:{
        ...mapGetters(
            [
                'getBalance',
                'getEmail',
            ]
        ),
        balance: {
            get() {
                return this.getBalance
            },
        },
        email: {
            get() {
                return this.getEmail
            },
        },
        routname(){
            console.log(this.$route)
        if(this.$route.fullPath == '/'){
            return  1
        }
        else if(this.$route.fullPath == '/balance'){
            return  2
        }
        }
    },
     methods:{
         sub() {
            this.setSubmit(true)
        },
        getValidationState({ dirty, validated, valid = null }) {
            if (this.submit) {
                return dirty || validated ? valid : null;
            }
        },
        ChangePasswordMethod() {
            let user = {
                old_password: this.old_password,
                new_password: this.new_password,
                new_password_replay: this.new_password_replay
            }
            this.$store.dispatch('ChangePassword', user)
                .then((response) => {
                    this.old_password = this.new_password = this.new_password_replay = null
                    this.error_change_pwd = 'success_password'
                }
                )
                .catch((err) => {
                    console.log('ERROR', err.response.error);
                    this.error_change_pwd = err.response.data.error
                })
        },
          ...mapActions(
            [
                'fetchTemplateInfo',
                'logout'
            ]
        ),
         close(){
             this.st=false
             this.form=false
         },
        getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
        },
    }
}
</script>
