<template>
  <div id="app" >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import EmptyLayout from './assets/layout/EmptyLayout.vue'
import MainLayout from './assets/layout/MainLayout.vue'
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'main') + '-layout'
    }
  },
  components: {
    EmptyLayout, MainLayout
  },
}
</script>

