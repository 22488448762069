<template>
    <div>
        <b-row class="w-100 mt-4">
            <b-col lg="6" style="overflow: hidden;" class="d-flex justify-content-center">
                <img src="../assets/img/registration.svg" style="width:60%" class="d-none d-lg-flex" alt="">
                <img src="../assets/img/registrationM.svg" style="width:60%"
                    class="d-flex d-lg-none mb-sm-5 mt-sm-5 mb-3" alt="">
            </b-col>
            <b-col xl="5" lg="6" class="d-flex justify-content-center align-items-center ps-xxl-5 pe-xxl-5">
                <b-card no-body class="cardA1 ms-sm-5 ms-4 me-sm-5 mb-3 mb-lg-0">
                    <div class="divcardA1">
                        <div class="divcardA1div" style="right:10px"></div>
                        <div class="divcardA1div" style="right:25px"></div>
                        <div class="divcardA1div" style="right:40px"></div>
                    </div>
                    <h1 class="cardA1H1 mt-4">Заполните анкету регистрации</h1>
                    <b-form @submit.prevent="registration()">
                        <validation-provider name="telegram" :rules="{ required: true, min: 3, max: 250 }"
                            v-slot="validationContext">
                            <b-form-group>
                                <b-form-input placeholder="Ник в Telegram (@username)" v-model="telegram"
                                    class="input  mt-3" :state="getValidationState(validationContext)"></b-form-input>

                                <b-form-invalid-feedback id="input-1-live-feedback">
                                    {{
                                            validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="E-mail" :rules="{ required: true, min: 3, email, max: 250 }"
                            v-slot="validationContext">
                            <b-form-group>
                                <b-form-input v-model="email" placeholder="E-mail" style="margin-top:25px"
                                    class="input " :state="getValidationState(validationContext)">
                                </b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                    {{
                                            validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="password" :rules="{ required: true, min: 8 }"
                            v-slot="validationContext">
                            <b-form-group>
                                <b-form-input class="input " style="margin-top:25px" placeholder="Пароль"
                                    type="password" v-model="password" :state="getValidationState(validationContext)"
                                    autocomplete="off">
                                </b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                    {{
                                            validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider name="password2" :rules="{ required: true, min: 8 }"
                            v-slot="validationContext">
                            <b-form-group>
                                <b-form-input class="input" style="margin-top:25px" placeholder="Пароль ещё раз"
                                    type="password" v-model="password2" :state="getValidationState(validationContext)"
                                    autocomplete="off"></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                    {{
                                            validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <b-row class="mt-4">
                            <b-col sm="6" class="d-flex">
                                <b-button class="btnPR w-100" type="submit">Регистрация</b-button>
                            </b-col>
                            <b-col sm="6" class="d-flex mt-3 mt-sm-0">
                                <router-link to="/login" class="mt-auto w-100">
                                    <b-button class="btnPR1 w-100">Авторизация</b-button>
                                </router-link>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
     metaInfo() {
    return {
      title: '',
      titleTemplate: "Регистрация – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/registration' }
      ]
    }
  },
    data() {
        return {
            telegram: null,
            email: null,
            password: null,
            password2: null,
        }
    },
    computed: {
        ...mapGetters(
            [
                'getSubmit'
            ]
        ),
        submit() {
            return this.getSubmit
        },
    },
    methods: {
        ...mapActions(
            [
                'setSubmit'
            ]
        ),
        getValidationState({ dirty, validated, valid = null }) {
            if (this.submit) {
                return dirty || validated ? valid : null;
            }
        },
        registration() {

            localStorage.removeItem('token')

            localStorage.removeItem('username')
            localStorage.removeItem('email')
            let user = {
                email: this.email,
                password: this.password,
                password2: this.password2,
                username: this.email,
                telegram: this.telegram
            }
            this.$store.dispatch('register', { user })
                .then(() =>
                    this.$router.push("/"))
                .catch(err => this.errorLogin = err.response.data.error,
                    this.setSubmit(false))
        },
    }
}
</script>