<template>
    <div style="width:100vw" class="d-flex">
        <b-row style="max-width:1488px;width:100%" class="ms-auto me-auto mt-5">
            <b-col lg="8">
                <img src="../assets/img/Pay.svg" style="width:90%" alt="">
                <div style="position:relative"  class="mt-4">
                    <div class="divcardB2S" style="background:#F1367C;height:400px"></div>
                <b-card no-body class="cardB2 d-flex" style="height:400px">
                    <div class="divcardB2">
                        <h2 class="H2Heading">Счёт на пополнение баланса</h2>
                    </div>
                    <span v-if="getErrorPayCreated() == null">
                    <div class="d-flex flex-column flex-sm-row justify-content-between mt-3">
                        <span class="P1Text">Пополнение баланса</span>
                        <span class="P1Text">{{ getAmount() }} €</span>
                    </div>
                    <div class="d-flex flex-column flex-sm-row justify-content-between mt-3">
                        <span class="P1Text">Способ оплаты</span>
                        <span class="P1Text">{{ getPaymentsMethod() }}</span>
                    </div>
                    </span>
                    <div class="d-flex w-100 justify-content-center text-center mt-3 h-100 align-contetn-center">
                        <span class="P1Text" style="color:red; " v-html=" getErrorPayCreated()"> </span>
                    </div>
                    <a class="mt-5 mt-sm-auto ms-auto me-auto mb-4" :href="PayLink" target="_blank" v-if="getErrorPayCreated() == null">
                    <b-button class="btnPR " style="padding: 0 30px">Подтвердить и оплатить</b-button>
                    </a>
                </b-card>
                </div>
            </b-col>
            <b-col lg="4" class="d-flex">
                <img src="../assets/img/Pay1.png" style="height:100%" class="d-none d-lg-block d-xxl-none ms-auto me-auto" alt="">
                <img src="../assets/img/Pay2.png" style="height:100%" class="d-none  d-xxl-block ms-auto me-auto" alt="">
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    metaInfo() {
    return {
      title: '',
      titleTemplate: "Оплата счета – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/confirmPay' }
      ]
    }
  },
    computed: {
    ...mapGetters(
      [
        'getPayLink',
      ]
    ),
    PayLink: {
      get() {
        return this.getPayLink
      },
    },
  },
  methods: {
    ...mapActions(
      [
        'fetchСreate',
      ]
    ),
    ...mapGetters(
      [
        'getOrderId',
        'getPaymentsMethod',
        'getGeneralCode',
        'getAmount',
        'getErrorPayCreated'
      ]
    ),
  }
}
</script>