<template>
<div>
    <div class="d-flex justify-content-center mt-5">
        <img src="../assets/img/error.svg" style="width:60vw;max-width:900px" alt="">
    </div>
    <div class="d-flex justify-content-center mt-5">
        <p class="cardA1p text-center" style="width:90vw;max-width:900px">К сожалению, что-то пошло не так. Запрашиваемая вами страница не существует. Возможно, она устарела, была удалена, либо адрес в адресной строке введен неправильно</p>
    </div>
     <div class="d-flex justify-content-center mt-5">
       <router-link to="/"><b-button class="btnPR" style="padding:0 50px">На главную страницу</b-button></router-link>
     </div>
</div>
</template>
<script>
export default {
    metaInfo() {
    return {
      title: '',
      titleTemplate: "Страница не найдена – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/*' }
      ]
    }
  },
}
</script>