<template>
  <b-container fluid  style="max-width:1488px;min-height:100vh">
    <TopNavBar></TopNavBar>
    <b-row>
          <transition name="component1-fade" mode="out-in" v-if="this.$route.fullPath == '/'">
            <router-view></router-view>
          </transition>
          <transition name="component-fade" mode="out-in" v-else>
            <router-view></router-view>
          </transition>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import TopNavBar from '@/components/TopNavBar.vue'
export default {
  components: {
    TopNavBar
  },
  methods: {
    ...mapGetters([
      'is_email_confirm',
    ]),
  },
  mounted() {
    console.log('EMAIL CONFIRM', this.is_email_confirm());
    setTimeout(() => {
      if (!this.is_email_confirm()) {
        this.$router.push('/user/account/email/confirm')
      }
    },1000)

  },
  }
</script>