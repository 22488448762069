<template>
  <div>
    <b-modal id="modal6" class="model1" ref="my-modal6" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH36M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Шаблон письма для заказа</h1>
      <p class="P1Text mt-4">Бесплатный вариант использования post-upsell (допродажа после оформления заказа) – это кастомизация письма о подтверждении заказа. Shopify автоматически отправляет шаблонные confirmation-письма, которые вы можете отредактировать таким образом, чтобы побудить вашего клиента после совершения покупки в магазине оформить новый заказ.</p>
     <div class="d-flex flex-column flex-lg-row mt-4 mb-4 align-items-center">
       <a href="https://docs.google.com/document/d/14E79w5yZFepO416ygyFUKP1M9vgxSVZEG5xAvm7FMl8/edit" target="_blank" class="widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
       <!-- <a href="https://www.instagram.com/p/CAA0F_HIjgy/" target="_blank" class="RL ms-lg-3 mt-3 mt-lg-0">перейти к инструкции <img src="../assets/img/Arrow4.svg" class="ms-2" alt=""> </a> -->
     </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal6'].hide()
    },
  }
}
</script>