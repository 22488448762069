<template>
    <div>
      <b-row class="w-100 mt-4">
          <b-col lg="6" style="overflow: hidden;" class="d-flex justify-content-center">
              <img src="../assets/img/login.svg" style="width:60%" class="d-none d-lg-flex" alt="">
              <img src="../assets/img/loginM.svg" style="width:60%" class="d-flex d-lg-none mb-sm-5 mt-sm-5 mb-3" alt="">
          </b-col>
          <b-col xl="5" lg="6" class="d-flex justify-content-center align-items-center ps-xxl-5 pe-xxl-5">
              <b-card no-body class="cardA1 ms-sm-5 ms-4 me-sm-5">
                  <div class="divcardA1">
                      <div class="divcardA1div" style="right:10px"></div>
                      <div class="divcardA1div" style="right:25px"></div>
                      <div class="divcardA1div" style="right:40px"></div>
                  </div>
                  <span v-if="success">
                      <h1 class="cardA1H1 mt-4">Ваш аккаунт активирован!</h1>
                      <p class="cardA1p mt-2">Теперь Вы можете зайти в личный кабинет.</p>
                  </span>
                  <span v-else>
                      <h1 class="cardA1H1 mt-4">Ошибка активации аккаунта!</h1>
                      <p class="cardA1p mt-2">{{ msg }}</p>
                  </span>
                  <b-row>
                      <b-col sm="6" class="d-flex" v-if="success">
                          <router-link to="/login" class="mt-auto w-100"  ><b-button class="btnPR w-100">Авторизация</b-button></router-link>
                      </b-col>
                      <b-col sm="6" class="d-flex" v-else>
                           <router-link  to="/user/account/email/confirm" class="mt-auto w-100"  > <b-button class="btnPR w-100">Отправить новый</b-button></router-link>
                        </b-col>
                      <b-col sm="6" class="d-flex mt-3 mt-sm-0 " v-if="success">
                          <img src="../assets/img/gal.svg" class="ms-auto me-auto" alt="">
                      </b-col>
                      <b-col sm="6" class="d-flex mt-3 mt-sm-0 " v-else>
                          <img src="../assets/img/notactive.svg" class="ms-auto me-auto" alt="">
                      </b-col>
                  </b-row>
              </b-card>
          </b-col>
      </b-row>
    </div>
</template>
<script>
import { httpClient } from "../HttpClientV1/HttpClient"
export default ({
    metaInfo() {
    return {
      title: '',
      titleTemplate: "Аккаунт активирован – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/user/account/activate/:key' }
      ]
    }
  },
    data() {
        return {
            msg: null,
            success: true
        }
    },
   mounted() {
        httpClient.get('api/v3/user/activate?key=' + this.$route.params.key)
            .then((response) => {
                if (response.data.status == 'ok') {
                    // this.msg = response.data.msg
                    this.success = true
                }
                else {
                    this.msg = response.data.msg
                    this.success = false
                }
            })
    }
})
</script>
