<template>
<div class="mt-5">
  <b-row class="mb-lg-5">
    <b-col xl="6" md="5">
      <img src="../assets/img/homeH1.svg" style="width:100%" alt="">
      <b-card no-body class="cardH1 mt-5">
        <div class="cardH1div" style="top:-6px;left:-6px"></div>
        <div class="cardH1div" style="top:-6px;right:-6px"></div>
        <div class="cardH1div" style="bottom:-6px;left:-6px"></div>
        <div class="cardH1div" style="bottom:-6px;right:-6px">
          <img src="../assets/img/arrowC.svg" style="position:absolute;bottom:-23px;right:-25px" alt="">
        </div>
        <p class="P1Text" style="color:#fff">Мы предлагаем полный комплекс услуг, позволяющий предпринимателям масштабировать свой бизнес, продавая товары в интернете по всему миру.</p>
        <p class="P1Text" style="color:#fff">Наша цель — помочь нашим клиентам построить прибыльный и устойчивый бизнес в сфере электронной коммерции за счет внедрения различных проверенных нами маркетинговых инструментов.</p>
      </b-card>
      <b-card class="cardH2 mt-5 d-flex flex-row mb-3" no-body>
        <div>
        <h2 class="H1Heading mt-2">Поддержка</h2>
        <p class="P1Text">Мы всегда на связи в Teleram. <br class="d-none d-sm-block"> Просто напишите нам, если у вас есть вопросы.</p>
        </div>
        <a href="https://tgmssg.ru/ecomtodaysupport" target="_blank" class="ms-auto">
        <div style="position:relative"  >
        <img src="../assets/img/teleg1.svg" style="width:100px" alt="">
        <img src="../assets/img/teleg2.svg" style="width:90px;position:absolute;left:5px;top:5px" class="teleg2" alt="">
        </div>
        </a>
      </b-card>
    </b-col>
    <b-col xl="6" md="7" class="mt-3 mt-md-0">
      <div style="width:100%;overflow:hidden;position:relative">
        <h1 class="H1Heading">Мы на Youtube</h1>
         <carousel
         id="slider1"
            ref="slider1"
            :navText="[ '&#x2039;', '&#x203a;']"
            :autoplay="true"
            :autoplayTimeout="8000"
            :items="8"
            :nav="true"
            :loop="true"
            :autoplayHoverPause="true"
            :animateOut="true"
            :animateIn="true"
            navSpeed="1000"
            class="d-flex mt-3 carusel1"
            autoplaySpeed="2000"
          >
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/NUe1_k8hiy4"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
            <h2 class="SsubHeading">Paypal, Stripe, Shopify Payments | Какую платёжную систему подключить? Прием платежей со всего мира🌍</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/tlnzA-l3Gh0"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                     <h2 class="SsubHeading">Как с нуля создать интернет-магазин на Shopify и продавать за рубеж? | Обзор платформы | Дропшиппинг</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/XRvti1zeuf4"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                     <h2 class="SsubHeading">🤯 Настройка рекламы в TikTok за 10 минут! | Полная пошаговая инструкция | Shopify Дропшиппинг</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/dGFkU"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                    <h2 class="SsubHeading">Ответы на популярные вопросы | Stripe или Paypal | Реклама в Facebook и TikTok | Shopify Дропшиппинг</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/Sg2_PbyPOoc"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                    <h2 class="SsubHeading">😱 Хватит сливать бюджет! ТОП ошибок, снижающих конверсию | Разбор дропшиппинг-магазинов на Shopify</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/jwuNYtNSKOs"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                     <h2 class="SsubHeading">Конец Facebook?😳 | Актуальные каналы трафика в 2021 | Настройка рекламы в TikTok, Google | Shopify</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/UFUnZA9sO6w"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                    <h2 class="SsubHeading">Как заработать на дропшиппинге 2021? Сколько денег нужно для старта? | Shopify Дропшиппинг</h2>
          </div>
          <div  class="divCar2">
            <b-embed 
                        type="iframe"
                        aspect="16by9"
                        src="https://www.youtube.com/embed/M_6NMYoOw-Y"
                        allowfullscreen
                        class="frame1"
                    ></b-embed>
                     <h2 class="SsubHeading">ТОП-5 Трендов В Shopify-Дропшиппинге В 2021 | ЧТО ИЗМЕНИЛОСЬ? ТЫ ДОЛЖЕН ЭТО ЗНАТЬ!</h2>
          </div>
         </carousel>
      </div>
      <div style="width:100%;overflow:hidden;position:relative" class="mt-3">
        <h1 class="H1Heading">Полезные материалы</h1>
         <carousel
         id="slider2"
            ref="slider2"
            :navText="[ '&#x2039;', '&#x203a;']"
            :autoplay="true"
            :autoplayTimeout="8000"
            :items="7"
            :nav="true"
            :loop="true"
            :autoplayHoverPause="true"
            :animateOut="true"
            :animateIn="true"
            navSpeed="1000"
            class="d-flex mt-3"
            style="width:2555px"
            autoplaySpeed="2000"
          >
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH31.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Чек-лист по поиску товаров</h2>
              <p class="P1Text mt-3">Команда Ecom Today на основе своего опыта подготовила для вас топовый чек-лист по поиску и отбору товаров.</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal1">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH32.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Чек-лист по поддержке клиентов</h2>
              <p class="P1Text mt-3">Подробный гайд по работе с покупателями, который значительно сэкономит ваши время и материальные ресурсы.</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal2">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH33.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Чек-лист по банам Facebook</h2>
              <p class="P1Text mt-3">Множество полезных актуальных советов и лайфхаков, которые значительно упростят процесс работы с Facebook.</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal3">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH34.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Более 30 Shopify-тем бесплатно</h2>
              <p class="P1Text mt-3">Мы готовы поделиться набором из нескольких платных Shopify-тем, абсолютно бесплатно.</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal4">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH35.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Шаблоны писем для корзины</h2>
              <p class="P1Text mt-3">Лучший способ вернуть покупателя — правильные серии автоматических писем о брошенной корзине</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal5">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH36.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Шаблон письма для заказа</h2>
              <p class="P1Text mt-3">Бесплатный вариант использования post-upsell  — это кастомизация письма о подтверждении заказа.</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal6">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
          <div  class="divCar1">
            <b-card no-body class="cardH3 d-flex">
              <img src="../assets/img/cardH37.png" class="imgcardH3" alt="">
              <h2 class="SsubHeading mt-2">Создание почты на домене</h2>
              <p class="P1Text mt-3">Если вы решили завести аккаунт для бизнеса, лучше использовать корпоративный адрес на домене вашего сайта.</p>
              <div class="d-flex mt-auto">
                <span class="P1Text" style="text-decoration:underline;cursor:pointer" @click="modal7">подробнее</span>
                <img src="../assets/img/arrow.svg" class="ms-3 mt-1" style="width:40px" alt="">
              </div>
            </b-card>
          </div>
         </carousel>
      </div>
    </b-col>
  </b-row>
  <modal1></modal1>
  <modal2></modal2>
  <modal3></modal3>
  <modal4></modal4>
  <modal5></modal5>
  <modal6></modal6>
  <modal7></modal7>
</div>
</template>
<script>
import modal1 from '../components/modal1.vue'
import modal2 from '../components/modal2.vue'
import modal3 from '../components/modal3.vue'
import modal4 from '../components/modal4.vue'
import modal5 from '../components/modal5.vue'
import modal6 from '../components/modal6.vue'
import modal7 from '../components/modal7.vue'
import carousel from 'vue-owl-carousel2'
export default {
  metaInfo() {
    return {
      title: '',
      titleTemplate: "Главная страница – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/' }
      ]
    }
  },
  components:{carousel,modal1,modal2,modal3,modal4,modal5,modal6,modal7},
  methods:{
    modal1(){
       this.$root.$emit('bv::show::modal', 'modal1', '#my-modal1')
    },
    modal2(){
       this.$root.$emit('bv::show::modal', 'modal2', '#my-modal2')
    },
    modal3(){
       this.$root.$emit('bv::show::modal', 'modal3', '#my-modal3')
    },
    modal4(){
       this.$root.$emit('bv::show::modal', 'modal4', '#my-modal4')
    },
    modal5(){
       this.$root.$emit('bv::show::modal', 'modal5', '#my-modal5')
    },
    modal6(){
       this.$root.$emit('bv::show::modal', 'modal6', '#my-modal6')
    },
    modal7(){
       this.$root.$emit('bv::show::modal', 'modal7', '#my-modal7')
    },
    
  }
}
</script>
