import { httpClient } from "../../HttpClientV1/HttpClient";
import router from '../../router/index'
import axios from "axios";
export default {
    state:{
        PaymentsMethods:[],
        method_code: null,
        amount: 0,
        eur: 0,
        usd: 0,
        uah: 0,
        general_index: null,
        payments_method:null,
        order_id:null,
        pay_link:null,
        error_pay_create: null,
        locale: localStorage.getItem("locale"),
        history:[]
    },
    mutations: {
        updateHistory(state, data){
            state.history = data
        },
        updatePayLink(state, data){
            state.pay_link = data
        },
        updateOrderId(state, data){
            state.order_id = data
        },
        updatePaymentsMethod(state, data){
            state.payments_method = data
        },
        updateMethodCode(state, data){
            state.method_code = data
        },
        updateIndex(state, data){
            state.general_index = data
        },
        updateEur(state, data){
            state.eur = data
        },
        updateUsd(state, data){
            state.usd = data
        },
        updateUah(state, data){
            state.uah = data
        },
        updateAmount(state, data){
            state.amount = data
        },
        updatePaymentsMethods(state, data){
            state.PaymentsMethods = data
        },
        updateErrorPayCreate(state, data){
            state.error_pay_create = data
        }
      },
      actions: {
        setGeneralCode(ctx, data){
            ctx.commit('updateGeneralCode', ctx.state.GeneralMethods[data].code)
        },
        setMethodCode(ctx, data){
            ctx.commit('updateMethodCode', data)
        },
        setAmount(ctx, data){
            ctx.commit('updateAmount', data)
            ctx.dispatch('fetchConvert')
        },
        fetchConvert(ctx) {
            var data = {
                amount:ctx.state.amount,
                method_code:ctx.state.method_code
            }
            httpClient.post("api/v3/payments/convert", data).then((response) => {
              ctx.commit("updateEur", response.data.rub);
              ctx.commit("updateUsd", response.data.usd);
              ctx.commit("updateUah", response.data.uah);
            });
          },
          fetchСreate(ctx) {
            var data = {
                amount:ctx.state.amount,
                general_code:ctx.state.general_code,
                method_code:ctx.state.method_code
            }
            httpClient.post('api/v3/payments/create', data).then((response) => {
                try{
                    if(typeof response.data['error'] !== undefined){
                        var error = ''
                        if (localStorage.getItem('locale') == 'En'){
                            error = response.data.error_en 
                        }
                        else{
                            error = response.data.error
                        }
                        
                        if(typeof response.data['response'] !== undefined){
                            if(typeof response.data.response['errors'] !== undefined){
                                error +=  '<br>' + response.data.response.errors.amount
                            }
                        }
                        ctx.commit('updateErrorPayCreate', error)
                    }
                }
                catch{
                    console.error('error');
                }
                try{
                    if(typeof response.data['error'] !== undefined){
                        var error = ''
                        if (localStorage.getItem('locale') == 'En'){
                            error = response.data.error_en 
                        }
                        else{
                            error = response.data.error
                        }
                        ctx.commit('updateErrorPayCreate', error)
                    }
                }
                catch{
                    console.error('error');
                }
              ctx.commit("updateAmount", response.data.amount);
              ctx.commit("updateGeneralCode", response.data.general_name);
              ctx.commit("updatePaymentsMethod", response.data.payments_method);
              ctx.commit("updateOrderId", response.data.order_id);
              ctx.commit("updatePayLink", response.data.pay_link);
              router.push("/confirmPay")
            });
          },
          fetchPaymentsMethods(ctx) {
            httpClient
            .get('api/v3/payments/payments_methods')
            .then((response) => {
              ctx.commit("updatePaymentsMethods", response.data);
              ctx.commit('updateMethodCode', response.data[0].value)
            })
          },
          fetchHistory(ctx) {
            httpClient.post("api/v3/balance/history").then((response) => {
              ctx.commit("updateHistory", response.data);
            });
        },
      },
      getters: {
        getPayLink: (state) => state.pay_link,
        getOrderId: (state) => state.order_id,
        getPaymentsMethod: (state) => state.payments_method,
        getMethodCode: (state) => state.method_code,
        getEur: (state) => state.eur,
        getUsd: (state) => state.usd,
        getUah: (state) => state.uah,
        getAmount: (state) => state.amount,
        getErrorPayCreated: (state) => state.error_pay_create,
        getPaymentsMethods: (state) => state.PaymentsMethods,
        getHistory: (state) => state.history
      }
}