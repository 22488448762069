<template>
  <div>
    <b-modal id="modal3" class="model1" ref="my-modal3" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH33M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Чек-лист по банам в Facebook</h1>
      <p class="P1Text mt-4"> Команда Ecom Today на основе многолетнего опыта работы с рекламой и сотрудничества с арбитражными командами подготовила чрезвычайно полезный и информативный алгоритм работы с аккаунтами Facebook Ads для сведения всех рисков блокировки к минимуму.</p>
      <p class="P1Text mt-4">Мы создали и структурировали множество полезных актуальных советов и лайфхаков, которые значительно упростят процесс работы с Facebook-рекламой, сэкономят ваше время, деньги и нервы, особенно с нынешней ситуацией и многочисленными банами.</p>
      <p class="P1Text mt-4">В документ входит следующая информация:</p>
      <ol class="P1Text mt-4">
        <li>Алгоритм работы: создание аккаунта (соц.аккаунт, БМ, Fan Page), прогрев аккаунта, поэтапная работа с аккаунтом с 1 по 6 день;</li>
        <li class="mt-2">Что делать при запрете рекламной деятельности (ЗРД);</li>
        <li class="mt-2">Преимущества и недостатки Facebook как источника трафика;</li>
        <li class="mt-2">Виды блокировок;</li>
        <li class="mt-2">Снижение риска получения бана;</li>
        <li class="mt-2">Схема работы с разными видами аккаунтов (бруты, автореги, фарм, аренда, покупные аккаунты);</li>
        <li class="mt-2">Что нельзя делать при работе с Facebook-рекламой;</li>
        <li class="mt-2">Технические особенности работы (платежки, уникализация материалов, очистка метаданных);</li>
        <li class="mt-2">Как работать c Facebook Ads e-commerce предпринимателю.</li>
      </ol>
      <p class="P1Text mt-4">После изучения материала:</p>
      <ul class="P1Text mt-4">
        <li>вы разберетесь с тем, как значительно снизить вероятность блокировки аккаунта Facebook;</li>
        <li class="mt-2">у вас будет конкретный алгоритм работы с FB БМ, соц. аккаунтами и Fan Page;</li>
        <li class="mt-2">вы научитесь разбираться в видах аккаунтов и будете работать только с тем, что вам подходит;</li>
        <li class="mt-2">у вас сложится более полная картина работы с FB Ads, вы по-другому посмотрите на процесс настройки рекламы и получите ответы на давно волнующие вас вопросы;</li>
        <li class="mt-2">значительно сэкономите как временные, так и материальные ресурсы.</li>
      </ul>
      <p class="P1Text mt-4">Объем документа – 17 страниц. Только экспертная информация, который мы пользуемся ежедневно. Без воды.</p>
 <a href="https://drive.google.com/file/d/1bFr3TdfdBphd7DY21gRW5QNMZV8dnb7I/view?usp=sharing" target="_blank" class="mt-4 mb-4 widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal3'].hide()
    },
  }
}
</script>