<template>
    <div>
      <b-row class="w-100 mt-4">
          <b-col lg="6"  style="overflow: hidden;" class="d-flex justify-content-center mt-4 mt-lg-0">
              <img src="../assets/img/password.svg" style="width:80%" class="d-none d-lg-flex" alt="">
              <img src="../assets/img/passwordM.svg" style="width:80%" class="d-flex d-lg-none mb-sm-5 mt-sm-5 mb-3" alt="">
          </b-col>
          <b-col xl="5" lg="6" class="d-flex justify-content-center align-items-center ps-xxl-5 pe-xxl-5">
              <b-card no-body class="cardA1 ms-sm-5 ms-3 me-sm-5 mt-4 mt-lg-0 mb-3 mb-lg-0">
                  <div class="divcardA1">
                      <div class="divcardA1div" style="right:10px"></div>
                      <div class="divcardA1div" style="right:25px"></div>
                      <div class="divcardA1div" style="right:40px"></div>
                  </div>
                  <h1 class="cardA1H1 mt-4">Введите свой e-mail</h1>
                  <p class="P1Text">Новый пароль будет отправлен на почту.</p>
                  <b-form @submit.prevent="password">
                                        <validation-provider name="E-mail"
                                            :rules="{ required: true, min: 3, email, max: 250 }"
                                            v-slot="validationContext">
                                            <b-form-group>
                                                <b-form-input v-model="email" placeholder="E-mail" 
                                                    class="input " :state="getValidationState(validationContext)">
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">
                                                    {{
                                                            validationContext.errors[0]
                                                    }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                          <div class="d-flex mt-3 justify-content-center text-center">
                                             {{errorLogin}}
                                        </div>
                          <b-button class="btnPR w-100 mt-4" @click="sub" type="submit">Восстановить пароль</b-button>
                   </b-form>
              </b-card>
          </b-col>
      </b-row>
      <modalP></modalP>
    </div>
</template>
<script>
import modalP from '../components/modalP.vue'
import { mapActions,mapGetters } from 'vuex'
export default {
    metaInfo() {
    return {
      title: '',
      titleTemplate: "Восстановление пароля – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/reset-password' }
      ]
    }
  },
    components:{
        modalP
    },
    data(){
        return{
            email:null,
            errorLogin: ''
        }
    },
     computed: {
        ...mapGetters([
            "getSubmit",
        ]),
        submit() {
            return this.getSubmit;
        },
    },
    methods:{
        sub() {
            this.setSubmit(true);
        },
        ...mapActions([
            "setSubmit",
        ]),
        getValidationState({ dirty, validated, valid = null }) {
            if (this.submit) {
                return dirty || validated ? valid : null;
            }
        },
        password() {
            let user = {
                email: this.email,
            };
             this.errorLogin = ''
            this.$store.dispatch("password", user)
                .then(() => {

                this.$root.$emit('bv::show::modal', 'modalP', '#modalP')
                })
                .catch(err => this.errorLogin = err.response.data.msg);
        },
    }
}
</script>