<template>
<div style="min-height:97vh">
  <div class="d-flex justify-content-center pt-4 mtEM">
    <router-link to="/">
   <img src="../../assets/img/logoE.svg" alt="">
   </router-link>
  </div>
    <router-view ></router-view>
  </div>
</template>
<script>
export default {
}
</script>
