import Vue from 'vue'
import Vuex from 'vuex'
import user from './mod/user'
import account from './mod/account'
import balance from './mod/balance'
import helpcenter from './mod/helpcenter'
import referral from './mod/referral'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    user,
    account,
    balance,
    helpcenter,
    referral
  },
})
