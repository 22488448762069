<template>
  <div>
    <b-modal id="modal5" class="model1" ref="my-modal5" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH35M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Шаблоны писем для корзины</h1>
      <p class="P1Text mt-4">Брошенные корзины — ночной кошмар владельцев интернет-магазинов. По некоторым данным почти 70% покупателей оставляют товары в корзине и не возвращаются. А это — колоссальные потери для интернет-магазина.</p>
      <p class="P1Text mt-4">Лучший способ вернуть покупателя — узнать, почему он оставляет товары в корзине и не оформляет заказ. После мы предлагаем решение проблемы и завершаем сделку. Сделать это можно с помощью правильной серии автоматических писем о брошенной корзине.</p>
      <p class="P1Text mt-4">Если клиент перешел в чекаут и оставил свой email или номер телефона, у нас есть шанс получить от него оплату, даже если он закрыл сайт.</p>
      <p class="P1Text mt-4">Отправлять письма тем, кто бросил корзину, легко и просто можно с помощью приложения Abandonment Protector. Всего за $8/мес. приложение предлагает широкий набор функций, одна из основных – возврат клиента. Мы рекомендуем отправлять цепочку из 3 писем:</p>
      <p class="P1Text mt-4">1 - напоминаем о брошенной корзине, через 5 минут</p>
      <p class="P1Text mt-1">2 - напоминаем + даем промокод на 10%, через 6 часов</p>
      <p class="P1Text mt-1">3 - напоминаем + даем промокод на 15%, через 19 часов</p>
      <p class="P1Text mt-4">Шаблоны писем можно найти по ссылке ниже.</p>
<a href="https://docs.google.com/document/d/1PQDDZfpqgYykVyKwoLyrIDlq-38qDHUN_cllp6xZ7o0/edit" target="_blank" class="mt-4 mb-4 widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal5'].hide()
    },
  }
}
</script>