<template>
    <div>
        <b-row class="w-100 mt-4">
            <b-col lg="6" style="overflow: hidden;" class="d-flex justify-content-center">
                <img src="../assets/img/login.svg" style="width:60%" class="d-none d-lg-flex" alt="">
                <img src="../assets/img/loginM.svg" style="width:60%" class="d-flex d-lg-none mb-sm-5 mt-sm-5 mb-3"
                    alt="">
            </b-col>
            <b-col xl="5" lg="6" class="d-flex justify-content-center align-items-center ps-xxl-5 pe-xxl-5">
                <b-card no-body class="cardA1 ms-sm-5 ms-4 me-sm-5">
                    <div class="divcardA1">
                        <div class="divcardA1div" style="right:10px"></div>
                        <div class="divcardA1div" style="right:25px"></div>
                        <div class="divcardA1div" style="right:40px"></div>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                    <span class="RL" style="cursor:pointer;text-decoration: underline;" @click="logout()">выход<img src="../assets/img/Arrow8.svg" class="ms-2" alt=""></span>
                    </div>
                    <h1 class="cardA1H1 mt-2">Активируйте ваш аккаунт!</h1>
                    <p class="cardA1p mt-2">Мы отправили письмо на вашу электронную почту.</p>
                    <div class="d-flex mt-3" v-if="msg != null">
                        <p class="P1Text ms-auto me-auto">{{ msg }}</p>
                    </div>
                    <div class="d-flex mt-3" v-if="msg1 != null">
                        <p class="P1Text ms-auto me-auto">{{ msg1 }}</p>
                    </div>
                    <b-row class="mt-3">
                        <b-col sm="6" class="d-flex">
                            <b-button class="btnPR w-100" @click="proverka()">Проверить</b-button>
                        </b-col>
                        <b-col sm="6" class="d-flex mt-3 mt-sm-0">
                            <b-button class="btnPR1 w-100" @click="send_new">Отправить ещё</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import { httpClient } from "../HttpClientV1/HttpClient"
export default ({
    metaInfo() {
    return {
      title: '',
      titleTemplate: "Активируйте аккаунт – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/user/account/email/confirm' }
      ]
    }
  },
    data() {
        return {
            msg: null,
            msg1: null,
            success: true
        }
    },

    methods: {
        ...mapActions(
            [
                'logout'
            ]
        ),
        proverka() {
            httpClient.get('api/v3/user/check_confirm?key=' + this.$route.params.key)
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.msg = response.data.msg
                        this.success = true
                        this.$router.push('/')
                    }
                    else {
                        this.msg = response.data.msg
                        this.success = false
                    }
                })
        },
        send_new() {
            httpClient.get('api/v3/user/send_confirm')
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.msg1 = response.data.msg
                        this.success = true
                    }
                    else {
                        this.msg1 = response.data.msg
                        this.success = false
                    }
                })
        }
    },

})
</script>