import { httpClient } from "../../HttpClientV1/HttpClient";
import router from '../../router/index'
import axios from "axios";
export default {
    state:{
        balance:0,
        username:null,
        email:null,
        email_confirm: true,
        password:null,
        status: "",
        token: localStorage.getItem("token") || "",
        user: {},
        user1: {},
        submit: false,
    },
    mutations: {
        updateSubmit(state, data) {
          state.submit = data;
        },
        updateBalance(state, data) {
            state.balance = data;
        },
        updateUsername(state, data) {
          state.username = data;
        },
        updateEmail(state, data) {
          state.email = data;
        },
        updateEmailConfirm(state, data) {
          state.email_confirm = data;
        },
        updatePassword(state, data) {
            state.password = data;
        },
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, token, user) {
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = "";
        },
      },
      actions: {
        setSubmit(ctx, data) {
          ctx.commit("updateSubmit", data);
        },
        fetchTemplateInfo(ctx) {
            httpClient.post("api/v3/user/info").then((response) => {
              ctx.commit("updateUsername", response.data.username);
              ctx.commit("updateEmail", response.data.email);
              ctx.commit("updateEmailConfirm", response.data.is_email_confirm);
              ctx.commit("updateBalance", response.data.balance);
            });
        },
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
              commit("auth_request");
              httpClient.post( "api/v3/user/login",  {user:user})
                .then((resp) => {
                  console.log(resp.data)
                  const token = resp.data.user.token;
                  localStorage.setItem("token", token);
                  axios.defaults.headers.common["Authorization"] = "Token " + token;
                  commit("auth_success", token, user);
                  commit("updateSubmit", false);
                  resolve(resp);
                })
                .catch((err) => {
                  commit("auth_error");
                  localStorage.removeItem("token");
                  reject(err);
                });
            });
          },
          password({ commit }, user) {
            return new Promise((resolve, reject) => {
              commit("auth_request");
              httpClient.post( "api/v3/user/reset_password",  user)
                .then((resp) => {
                  resolve(resp);
                })
                .catch((err) => {
                  commit("auth_error");
                  reject(err);
                });
            });
          },
          ChangePassword({ commit }, user) {
            return new Promise((resolve, reject) => {
              commit("auth_request");
              httpClient.post( "api/v3/user/change_password",  user)
                .then((resp) => {
                  commit("updateSubmit", false);
                  resolve(resp);
                })
                .catch((err) => {
                  commit("auth_error");
                  reject(err);
                });
            });
          },
          register({ commit }, user) {
            return new Promise((resolve, reject) => {
              commit("auth_request");
              httpClient.post( "api/v3/user/registration", user)
                .then((resp) => {
                  
                  const token = resp.data.token;
                  localStorage.setItem("token", token);
                  axios.defaults.headers.common["Authorization"] = "Token " + token;
                  commit("auth_success", token, user);
                  commit("updateSubmit", false);
                  resolve(resp);
                })
                .catch((err) => {
                  commit("auth_error", err);
                  localStorage.removeItem("token");
                  reject(err);
                });
            });
          },
          logout({ commit }) {
            return new Promise((resolve) => {
              commit("logout");
              localStorage.removeItem("token");
              delete axios.defaults.headers.common["Authorization"];
              router.push('/login')
              resolve();
            });
          },
      },
      getters: {
        getSubmit: (state) => state.submit,
        getBalance: (state) => state.balance,
        getUsername: (state) => state.username,
        getEmail: (state) => state.email,
        is_email_confirm:(state) => state.email_confirm,
        isLoggedIn: state => !!state.token,
      }
}