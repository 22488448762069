<template>
  <div>
    <b-row class="w-100 mt-4">
      <b-col lg="6" style="overflow: hidden;" class="d-flex justify-content-center">
        <img src="../assets/img/login.svg" style="width:60%" class="d-none d-lg-flex" alt="">
        <img src="../assets/img/loginM.svg" style="width:60%" class="d-flex d-lg-none mb-sm-5 mt-sm-5 mb-3" alt="">
      </b-col>
      <b-col xl="5" lg="6" class="d-flex justify-content-center align-items-center ps-xxl-5 pe-xxl-5">
        <b-card no-body class="cardA1 ms-sm-5 ms-4 me-sm-5 mb-3 mb-lg-0">
          <div class="divcardA1">
            <div class="divcardA1div" style="right:10px"></div>
            <div class="divcardA1div" style="right:25px"></div>
            <div class="divcardA1div" style="right:40px"></div>
          </div>
          <h1 class="cardA1H1 mt-4">Введите логин и пароль</h1>
          <b-form @submit.prevent="Login">
            <validation-provider name="E-mail" :rules="{ required: true, min: 3, email, max: 250 }"
              v-slot="validationContext">
              <b-form-group>
                <b-form-input v-model="email" placeholder="E-mail" style="margin-top:25px" class="input "
                  :state="getValidationState(validationContext)">
                </b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{
                      validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider name="password" :rules="{ required: true, min: 8 }" v-slot="validationContext">
              <b-form-group>
                <b-form-input class="input " style="margin-top:25px" placeholder="Пароль" type="password"
                  v-model="password" :state="getValidationState(validationContext)" autocomplete="off">
                </b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{
                      validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="mt-3">
              <router-link to="/reset-password" class="RL">Восстановить пароль</router-link>
            </div>
            <div class="d-flex mt-3 justify-content-center">
              {{ errorLogin }}
            </div>
            <b-row class="mt-3">
              <b-col sm="6" class="d-flex">
                <b-button class="btnPR w-100" type="submit" @click="sub">Авторизация</b-button>
              </b-col>
              <b-col sm="6" class="d-flex mt-3 mt-sm-0">
                <router-link to="/registration" class="mt-auto w-100">
                  <b-button class="btnPR1 w-100">Регистрация</b-button>
                </router-link>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  metaInfo() {
    return {
      title: '',
      titleTemplate: "Авторизация – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/login' }
      ]
    }
  },
  data() {
    return {
      email: null,
      password: null,
      errorLogin: ''
    }
  },
  computed: {
    ...mapGetters(
      [
        'getSubmit',
      ]
    ),
    submit() {
      return this.getSubmit
    },
  },
  methods: {
    sub() {
      this.setSubmit(true)
    },
    ...mapActions(
      [
        'setSubmit',
      ]
    ),
    getValidationState({ dirty, validated, valid = null }) {
      if (this.submit) {
        return dirty || validated ? valid : null;
      }
    },
    Login() {

      localStorage.removeItem('token')

      localStorage.removeItem('username')
      localStorage.removeItem('email')
      let user = {
        email: this.email,
        password: this.password
      }
      this.$store.dispatch('login', user)
        .then(() =>

          window.location.replace('/')
          // this.$router.push("/")
        )
        .catch(err => this.errorLogin = 'Неверный email или пароль')
    },
  }
}
</script>