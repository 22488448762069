<template>
  <div>
    <b-modal id="modal4" class="model1" ref="my-modal4" centered hide-footer hide-header size="lg">
      <div class="closebtn" @click="hideModal">
      <div  style="width:10px;height:10px;margin-top:0px;margin-left:8px">
        <img src="../assets/img/close.svg" alt="">
      </div>
      </div>
      <img src="../assets/img/cardH34M.png" class="imgcardH3M" alt="">
      <h1 class="H1Heading mt-3">Более 30 тем Shopify Бесплатно</h1>
      <p class="P1Text mt-4">Команда Ecom Today на основе своего опыта подготовила для вас топовый чек-лист по поиску и отбору товаров.</p>
      <p class="P1Text mt-4">Список тем:
 Adeline - free
, Annabelle, Basel
, Booster
, Debutify
, Decortica
, District
, Ella
, Empire
, Envy
, Foodly
, Gemini-fashion
, Icon
, Kagami
, Konversion
, Launch
, Minimart
, Mogo
, Pipeline
, Prestige
, Responsive
, Retina
, Sample
, Shoptimzed
, Startup
, Symmetry
, Testament
, Triss
, Turbo (Portland, Florence, Dubai, Seoul, Tennessee, Chicago)</p>
<a href="https://drive.google.com/drive/u/0/folders/1BqX5ILJFObFjzfhgs3ouzyoeJxpWuFGC" target="_blank" class="mt-4 mb-4 widthbtn"><b-button class="btnPR widthbtn " style="padding:0 30px">Перейти к материалам</b-button></a>
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    hideModal() {
      this.$refs['my-modal4'].hide()
    },
  }
}
</script>