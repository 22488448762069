import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import activete1 from '../views/activete1.vue'
import activete2 from '../views/activete2.vue'
import registration from '../views/registration.vue'
import login from '../views/login.vue'
import password from '../views/reset-password.vue'
import error from '../views/error.vue'
import balance from '../views/balance.vue'
import confirmPay from '../views/confirmPay.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{requiresAuth: true,layout:'main'},
    component: Home
  },
  {
    path: '/confirmPay',
    name: 'confirmPay',
    meta:{requiresAuth: true,layout:'empty'},
    component: confirmPay
  },
  {
    path: '/balance',
    name: 'balance',
    meta:{requiresAuth: true,layout:'main'},
    component: balance
  },
  {
    path: '/user/account/email/confirm',
    name: 'activete1',
    meta:{requiresAuth: true,layout:'empty'},
    component: activete1
  },
  {
    path: '/user/account/activate/:key',
    name: 'activete2',
    meta:{requiresAuth: false,layout:'empty'},
    component: activete2
  },
  {
    path: '/registration',
    name: 'registration',
    meta:{requiresAuth: false,layout:'empty'},
    component: registration
  },
  {
    path: '/login',
    name: 'login',
    meta:{requiresAuth: false,layout:'empty'},
    component: login
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta:{requiresAuth: false,layout:'empty'},
    component: password
  },
  { 
      path: '/*', 
      name: 'error', 
      meta:{requiresAuth: false,layout:'empty'},
      component:error 
  },
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior: (to,from, ) => {
      return { selector: to.hash, behavior: 'smooth', };
  },
  linkActiveClass: "active",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();

  }
  if(to.query.ref){   
    localStorage.setItem('referral_code',to.query.ref)
  }
  if(to.query.utm_source){
    localStorage.setItem('utm_source', to.query.utm_source)
    localStorage.setItem('utm_medium', to.query.utm_medium)
    localStorage.setItem('utm_campaign', to.query.utm_campaign)
    localStorage.setItem('utm_content', to.query.utm_content)
    console.log('ROUTE QUERY',to.query);
  }
  next()

});
export default router
