<template>
    <div style="min-height:90vh " class="d-flex align-items-xl-center mt-4 mt-xl-0">
        <div class="w-100">
        <b-row >
            <b-col xl="5">
                        <b-card no-body class="cardA1 heightcard" >
                            <div class="divcardA1" style="background:#F1367C">
                                <div class="divcardA1div" style="right:10px"></div>
                                <div class="divcardA1div" style="right:25px"></div>
                                <div class="divcardA1div" style="right:40px"></div>
                            </div>
                            <div class="d-flex mt-4">
                               <h1 class="cardA1H1 mt-2">Пополнение баланса</h1>
                               <img src="../assets/img/cardB1.svg" class="ms-auto" alt="">
                            </div>
                            <b-form>
                                <b-form-group>
                                    <b-form-select class="input w-100 mt-3" v-model="MethodCode" :options="getPaymentsMethods()"></b-form-select>
                                </b-form-group>
                                <b-form-group>
                                    <b-form-input class="input mt-3" v-model="amount" placeholder="Введите сумму в Евро (€)"  type="number"></b-form-input>
                                </b-form-group>
                            </b-form>
                            <div class="d-flex flex-column flex-lg-row align-items-center mt-3">
                                <b-button class="btnPR order-2 order-lg-1 mt-3 mt-sm-0 widthbtn" style="padding:0 40px" @click="fetchСreate()">Оплатить</b-button>
                                <div class="d-flex ms-auto order-1 order-lg-2 align-items-center">
                                    <div class="d-flex flex-column align-items-end">
                                    <span class="P1Text">Итого к оплате:</span>
                                        <span class="SsubHeading">{{getUsd}}  <img src="../assets/img/$.svg" alt=""> </span>
                                        <span class="SsubHeading">{{getUah}} <img src="../assets/img/₴.svg" alt=""> </span>
                                        <span class="SsubHeading">{{getEur}}  <img src="../assets/img/₽.svg" alt=""> </span>
                                    </div>
                                </div>
                            </div>
                        </b-card>
            </b-col>
            <b-col xl="7"  class="mt-4 mt-xl-0 mb-4 mb-lg-0">
                <div style="position:relative" >
                    <div class="divcardB2S"></div>
                <b-card no-body class="cardB2">
                    <div class="divcardB2">
                        <h2 class="H2Heading">История операций</h2>
                    </div>
                    <b-table
                        :items="getHistory()"
                        :fields="c1"
                        hover
                        responsive
                        sticky-header
                        class="d-none d-sm-block"
                    >
                    <template #cell(amount)="data">
                         <div v-if="data.item.type == 'DW'" style="color:red">-{{data.value}}€</div>
                         <div v-else>{{data.value}}€</div>
                    </template>
                    </b-table>
                    <b-table
                        :items="getHistory()"
                        :fields="c1"
                        hover
                        stacked
                        responsive
                        sticky-header
                        class="d-block d-sm-none"
                    >
                    <template #cell(amount)="data">
                         <div v-if="data.item.type == 'DW'" style="color:red">-{{data.value}}€</div>
                         <div v-else>{{data.value}}€</div>
                    </template>
                    </b-table>
                </b-card>
                </div>
            </b-col>
        </b-row>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    metaInfo() {
    return {
      title: '',
      titleTemplate: "Баланс – ЛК Ecom Today",
      link: [
        { rel: 'canonical', href: 'https://account.ecomlife.pro/balance' }
      ]
    }
  },
    mounted(){
        try{
        this.fetchPaymentsMethods(0)
        }
        catch(error) {
            console.error(error)
        }
        this.fetchHistory()
    },
    computed:{
    ...mapGetters(
      [
        'getEur',
        'getUsd',
        'getUah',
        'getAmount',
        'getMethodCode'
      ]
    ),
    amount: {
      get() {
        return this.getAmount
      },
      set(amount) {
          if(amount == ''){
                amount= 0
            }
            console.log('amount',amount)
        this.setAmount(amount)
      }
    },
    MethodCode:{
        get() {
        return this.getMethodCode
      },
      set(value) {
        this.setMethodCode(value)
      }
    }
    },
    methods:{
        ...mapActions(
      [
        'fetchPaymentsMethods',
        'fetchConvert',
        'setAmount',
        'fetchСreate',
        'setMethodCode',
        'fetchHistory'
      ]
    ),
    ...mapGetters(
      [
        'getPaymentsMethods',
        'getMethodCode',
        'getHistory',
      ]
    ),
    },
   data(){
       return{
           c1:[
                        {
                        key: "created",
                        label: 'Дата',
                        sortable: false,
                        class:'SsubHeading'
                        },
                        {
                        key: "operation",
                        label: 'Операция',
                        sortable: false,
                        class:'SsubHeading'
                        },
                        {
                        key: "amount",
                        label: 'Сумма',
                        sortable: false,
                        class:'SsubHeading'
                        },
              ],
       }
   }
}
</script>